import React from "react"
import { graphql } from "gatsby"
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {Header} from "../components/Header";
import {ChildImageSharp} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.markdownRemark.frontmatter.title} pageDescription={data.markdownRemark.frontmatter.description}>
            <Header image={data.desktopImage.childImageSharp.gatsbyImageData} />
            <Container
                fluid={'lg'}
                className={'text-center text-md-left'}
            >
                <Row as={'section'} className={'my-5 mb-md-0'}>
                    <Col md={6}>
                        <div
                            className={'content content-left'}
                        >
                            <h2>
                                <span className={'bg-primary'}>Assen,</span> Familiestad in 't Groen
                            </h2>
                            <p>
                                Assen is de hoofdstad van de provincie Drenthe. Het is een sterk groeiende middelgrote stad van nu nog zo’n 67.000 inwoners, met een gezellig stadscentrum en een paar kleine dorpen in haar nabijheid. Assen staat bekend als comfortabele familiestad. Het is hier goed wonen. In alle woonwijken zijn supermarkten, scholen en andere voorzieningen in de buurt.
                            </p>
                            <p>
                                Er is een uitgebreid winkel-centrum, een groot aanbod van lagere scholen en middelbaar (beroeps) onderwijs en diverse grand cafés en restaurants voor een gezellig middagje borrelen op het terras of een culinair avondje uit aan bijvoorbeeld de Brink of de Markt.
                            </p>
                        </div>
                    </Col>
                    <Col>
                        <StaticImage
                            alt={""}
                            aspectRatio={524/367}
                            src={"../img/sfeer/kloosterveste.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'align-items-center my-5 mt-md-n3'}>
                    <Col md={{ span: 6, order: 1 }}>
                        <div
                            className={'content content-right'}
                        >
                            <h2>
                                <span className={'bg-primary'}>Direct</span> Onderweg
                            </h2>
                            <p>
                                De centrale ligging maakt Assen een zeer aantrekkelijke woonplaats voor bewoners die in Groningen of elders werken. Het station is vanuit elk deel van de stad snel bereikbaar en ook zit je in no time op de uitvalswegen en de A28. Binnen een half uur sta je op de Grote Markt in Groningen.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={""}
                            aspectRatio={708/431}
                            src={"../img/sfeer/beeld bij direct onderweg.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'align-items-center my-5'}>
                    <Col md={6}>
                        <div
                            className={'content content-left'}
                        >
                            <h2>
                                Assen <span className={'bg-primary'}>Bruist</span>
                            </h2>
                            <p>
                                De wekelijkse markt rondom de Brink, een middagje shoppen in het gezellige centrum, een avondje uit in theater en bioscoop De Nieuwe Kolk of cultuur opsnuiven in het Drents Museum met spraakmakende (inter)nationale tentoonstellingen. Er is hier zoveel te zien, te doen en te beleven.
                            </p>
                            <p>
                                En natuurlijk: wie aan Assen denkt, gaat niet voorbij aan de TT. Een jaarlijkse happening, waarbij het centrum en het circuit een weekend lang feestelijk worden ingenomen door motorliefhebbers en festivalgangers vanuit de hele wereld.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={""}
                            aspectRatio={673/411}
                            src={"../img/sfeer/GettyImages-92012955.jpg"} />
                    </Col>
                </Row>
                <Row as={'section'} className={'align-items-center my-5 mt-md-n3'}>
                    <Col md={{ span: 6, order: 1 }}>
                        <div
                            className={'content content-right'}
                        >
                            <h2>
                                Stad in het <span className={'bg-primary'}>Groen</span>
                            </h2>
                            <p>
                                Het groene karakter van de stad is tot aan het centrum zichtbaar in de vorm van het Asserbos, een van de oudste bossen in Nederland. Direct aan de oostkant van de stad, ligt het beek- en esdallandschap Nationaal Park en Landschap de Drentsche Aa.
                                </p>
                            <p>
                                Wie nog iets verder kijkt, ziet dat heel Drenthe overstroomt van flora en fauna. Neem De Hondsrug, het Balloërveld of Fochteloërveen; daar is zoveel te ontdekken! Of het Doldersummerveld, de Gasterse Duinen en het Holtingerveld, die in nazomer helemaal paars kleuren. En wat te denken van heerlijk fietsen en wandelen in het Mantingerveld en het Sleenerzand. Wat een rust, ruimte en recreatie vind je hier.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <StaticImage
                            alt={""}
                            aspectRatio={673/411}
                            src={"../img/sfeer/GettyImages-516335209.jpg"} />
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    markdownRemark: IPage
    desktopImage: ChildImageSharp
}

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "assen"}}) {
            ...Page
        }
        desktopImage: file(relativePath: {eq: "sfeer/nieuw hoofdbeeld-pagina Assen.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    aspectRatio: 4
                    transformOptions: {cropFocus: ATTENTION}
                    layout: FULL_WIDTH
                )
            }
        }
    }
`
